import { ImageEntity } from "../entities/file/image.entity";
import { BrandCampaignPost } from "./brandCampaignPost";
import {
  LinkedInPostContentType,
  PostPlatform,
  PostTargetPlatforms,
} from "../enums/campaignPost.enums";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class BrandCampaignLinkedInPost extends BrandCampaignPost {
  images!: ImageEntity[];
  isDarkPost!: boolean;
  link!: string;
  targetPlatforms = [PostTargetPlatforms.LinkedIn];
  platform = PostPlatform.LinkedIn;
  text!: string;
  videoId?: string;
  videoFileId!: number | null;
  videoName?: string;
  videoUrl?: string;
  willBecomeAd?: boolean;
  linkedInOrganizationName!: string | null;
  linkedInOrganizationLogo!: string | null;

  static create(mediaType: LinkedInPostContentType): BrandCampaignLinkedInPost {
    return new BrandCampaignLinkedInPost({ mediaType: mediaType });
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "images":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }
}
