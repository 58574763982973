import dayjs from "dayjs";

import {
  CampaignDatesType,
  GoogleAdsBiddingStrategyType,
  PaymentType,
} from "../enums/campaign.enums";
import { UrlPrefix } from "./appData";
import { BrandCampaignCalendar } from "./brandCampaignCalendar";
import { BrandCampaignDetails } from "./brandCampaignDetail";
import { Campaign } from "./campaign";
import { SerializableObject } from "./serializable-object";
import {
  BrandAddressEntityToBrandAddressMapper,
  BrandEntityToBrandMapper,
} from "../../features/brand/data/mappers/brand.mapper";
import { safeLocalStorage } from "../utils/safe-storage";
import { FacebookTargetingCategoryCode } from "./facebookTargeting";

const currentCampaignLanguageKey = "currentBrandCampaignLanguage-";

export type BrandCampaignStatusFilter = Partial<
  Record<BrandCampaignStatus, boolean>
>;

export class BrandCampaign extends Campaign {
  ageRanges!: string[];
  allowPartnerIncludeWebLink!: boolean;
  brandPartnersImportToken!: string;
  brandedContentPageId!: string;
  campaignBrandStatus!: BrandCampaignStatus;
  googleAdGroupBid!: number;
  googleAdsBiddingStrategyType!: GoogleAdsBiddingStrategyType;
  invitationCode!: string;
  numberOfPartners!: number;
  postsCtaText!: string;
  publisherPlatformAudienceNetwork!: boolean;
  publisherPlatformFacebook!: boolean;
  publisherPlatformInstagram!: boolean;
  defaultBrandLogoUrl!: string;
  useDefaultBrandLogo!: boolean;
  details!: BrandCampaignDetails[];
  googleAdsAudienceIds!: number[];
  googleAdsAudienceNames!: string[];
  facebookTargetingTypes!: BrandFacebookTargetingType[];
  facebookAdsDefaultHeading!: string;
  facebookAdsDefaultDescription!: string;
  totalPostLogs!: number;
  totalPublishedPostLogs!: number;
  totalScheduledPostLogs!: number;
  totalPublishedAds!: number;
  totalScheduledAds!: number;
  totalNumberOfPartners!: number;
  landingPageLink!: string | null;
  landingPageLinkPrefix!: UrlPrefix | null;
  adDatesType!: CampaignDatesType;
  adTargetRadius!: number;
  paymentTypePercentage!: number;
  noAutoStartGoogleAds!: boolean;
  googleAdsBlackListPlacements!: string[];
  googleAdsWhiteListPlacements!: string[];
  landingPageId!: number;
  totalAdsInCalendar!: number;

  get isEditable(): boolean {
    return this.campaignBrandStatus === BrandCampaignStatus.Draft;
  }

  get currentLanguage(): string {
    // get list of languages for populating the language selector
    const languages = this.details.map((item) => item.language);
    if (safeLocalStorage.getItem(currentCampaignLanguageKey + this.id)) {
      const lang = languages.filter(
        (l) =>
          l.locale ===
          safeLocalStorage.getItem(currentCampaignLanguageKey + this.id),
      );
      if (lang.length > 0) {
        // maintain previously selected language
        return safeLocalStorage.getItem(currentCampaignLanguageKey + this.id)!;
      }
    }
    return this.details[0].language.locale;
  }

  get currentDetails(): BrandCampaignDetails {
    const currentDetails = this.details.filter(
      (d) => d.language.locale === this.currentLanguage,
    );
    if (currentDetails.length > 0) {
      // maintain previously selected language
      return currentDetails[0];
    }
    // set first 'details' object as the current details
    return this.details[0];
  }

  get currentCalendar(): BrandCampaignCalendar {
    return this.currentDetails.calendars[0];
  }

  get budgetPerAdPerPartner(): number {
    if (!this.budget) {
      return 0;
    }
    if (!this.maxAdsCampaignPartner) {
      return 0;
    }
    return +(this.budget / this.maxAdsCampaignPartner).toFixed(2);
  }

  get budgetPerDayPerPartner(): number {
    if (!this.budget) {
      return 0;
    }
    if (!this.maxAdsCampaignPartner) {
      return 0;
    }
    if (!this.adDuration) {
      return 0;
    }
    return +(
      this.budget /
      (this.adDuration * this.maxAdsCampaignPartner)
    ).toFixed(2);
  }

  changeLanguage(locale: string) {
    const details = this.details.filter((d) => d.language.locale === locale);
    if (details.length > 0) {
      safeLocalStorage.setItem(
        currentCampaignLanguageKey + this.id,
        details[0].language.locale,
      );
    }
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "startDate":
        return dayjs(propertyValue).toDate();
      case "endDate":
        return dayjs(propertyValue).toDate();
      case "fbCampaignStartDate":
        return dayjs(propertyValue).toDate();
      case "fbCampaignEndDate":
        return dayjs(propertyValue).toDate();
      case "details":
        return propertyValue.map(
          (details: any) => new BrandCampaignDetails(details),
        );
      case "brand":
        const brandMapper = new BrandEntityToBrandMapper(
          new BrandAddressEntityToBrandAddressMapper(),
        );
        return brandMapper.map(propertyValue);
      default:
        return propertyValue;
    }
  }

  public get logo(): string | undefined {
    if (this.useDefaultBrandLogo) {
      return this.brand?.logo ?? this.signUpLogo;
    }

    return this.signUpLogo ?? this.brand?.logo;
  }

  get budgetPerAd(): number {
    let budget = this.budget / this.maxAdsCampaignPartner;
    if (this.paymentType === PaymentType.CoFunded) {
      budget /= 2;
    }
    return budget;
  }
}

export class BrandFacebookTargetingType extends SerializableObject {
  id!: string;
  name!: string;
  classType!: string;
  code!: FacebookTargetingCategoryCode;
  type!: string;
}

export enum BrandCampaignStatus {
  All = "all",
  Draft = "draft",
  PendingValidation = "pending_validation",
  Upcoming = "upcoming",
  Active = "active",
  Past = "past",
}

export interface BrandCampaignStatusConfig {
  value: number;
  label: string;
  icon: string;
}

export const BRAND_CAMPAIGN_STATUS_FILTERS: Partial<
  Record<BrandCampaignStatus, BrandCampaignStatusConfig>
> = {
  [BrandCampaignStatus.Draft]: {
    value: 0,
    label: "shared.brandCampaignStatus.draft",
    icon: "ic_draft",
  },
  [BrandCampaignStatus.PendingValidation]: {
    value: 1,
    label: "shared.brandCampaignStatus.pending",
    icon: "ic_pendingVerification",
  },
  [BrandCampaignStatus.Upcoming]: {
    value: 2,
    label: "shared.brandCampaignStatus.upcoming",
    icon: "ic_upcoming",
  },
  [BrandCampaignStatus.Active]: {
    value: 3,
    label: "shared.brandCampaignStatus.inProgress",
    icon: "ic_inProgress",
  },
  [BrandCampaignStatus.Past]: {
    value: 4,
    label: "shared.brandCampaignStatus.finished",
    icon: "ic_finished",
  },
};
