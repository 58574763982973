import { FacebookPost } from "../types/campaignPost.types";
import { BrandCampaignPost } from "./brandCampaignPost";
import {
  CampaignPostContentType,
  PostPlatform,
  PostTargetPlatforms,
} from "../enums/campaignPost.enums";
import { ImageEntity } from "../entities/file/image.entity";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class BrandCampaignFacebookPost extends BrandCampaignPost {
  carouselSlides!: ImageEntity[];
  isDarkPost!: boolean;
  link!: string;
  targetPlatforms!: PostTargetPlatforms[];
  text!: string;
  videoId?: string;
  videoFileId!: number | null;
  videoName?: string;
  videoUrl?: string;
  willBecomeAd?: boolean;
  postToFacebook!: boolean;
  postToInstagram!: boolean;
  hasLinkPostValidLink!: boolean;
  platform = PostPlatform.Facebook;

  static create(
    postToInstagram = true,
    mediaType: CampaignPostContentType,
  ): BrandCampaignFacebookPost {
    const post = new BrandCampaignFacebookPost();
    post.carouselSlides = [];
    post.targetPlatforms = [];
    post.postToFacebook = true;
    post.postToInstagram = postToInstagram;
    post.isDarkPost = false;
    post.willBecomeAd = false;
    post.mediaType = mediaType;
    post.text = "";
    post.hasLinkPostValidLink = false;
    return post;
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "carouselSlides":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }

  public isFacebookPostInstance(): this is FacebookPost {
    return true;
  }
}
