import { Router } from "@angular/router";
import { map, switchMap, tap } from "rxjs/operators";
import { BehaviorSubject, lastValueFrom, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { BannerEntity } from "../../../../shared/entities/file/banner.entity";
import { VideoEntity } from "../../../../shared/entities/file/video.entity";
import { BrandNotSelectedError } from "../../../../shared/errors/brand-not-selected-error";
import { CampaignNotFoundError } from "../../../../shared/errors/campaign-not-found-error";
import {
  BrandCampaign,
  BrandCampaignStatus,
} from "../../../../shared/models/brandCampaign";
import { BrandCampaignDetails } from "../../../../shared/models/brandCampaignDetail";
import { BrandService } from "../../../../shared/services/api/brand.service";
import { HttpClient } from "../../../../shared/services/http-client";
import { NotificationService } from "../../../../shared/services/notification.service";
import { BrandUrl } from "../../../brand.url";
import { BrandCampaignPromoAdsPage } from "../../brand-campaign-promo-ads/brand-campaign-promo-ads-page";
import { AnyToBannerEntityMapper } from "../../../../shared/mappers/banner.entity.mapper";
import { AnyToVideoEntityMapper } from "../../../../shared/mappers/video.entity.mapper";

export const CAMPAIGN_ACCESS_DENIED = "ACCESS_DENIED";

@Injectable()
export class BrandCampaignService {
  private path = "brand";

  private isLoadingCampaignSource = new BehaviorSubject<boolean>(false);
  public isLoadingCampaign$: Observable<boolean> =
    this.isLoadingCampaignSource.asObservable();

  public readonly currentBrandCampaign$ = new BehaviorSubject<
    BrandCampaign | undefined
  >(undefined);

  public promoAdsTabChangedSource = new Subject<BrandCampaignPromoAdsPage>();
  public promoAdsTabChanged$: Observable<BrandCampaignPromoAdsPage> =
    this.promoAdsTabChangedSource.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly brandService: BrandService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  public getCurrentCampaign(): BrandCampaign | undefined {
    return this.currentBrandCampaign$.getValue();
  }

  public getCurrentCampaignId(): number | undefined {
    return this.getCurrentCampaign()?.id;
  }

  public get currentBrandCampaign(): BrandCampaign {
    if (!this.currentBrandCampaign$.value) {
      throw new CampaignNotFoundError();
    }
    return this.currentBrandCampaign$.value;
  }

  public get currentBrandCampaignId(): number {
    return this.currentBrandCampaign.id;
  }

  public async loadBrandCampaign(
    campaignId: number,
  ): Promise<BrandCampaign | undefined> {
    this.currentBrandCampaign$.next(undefined);
    this.isLoadingCampaignSource.next(true);

    const brandId = this.brandService.currentBrandId;

    try {
      const response = await lastValueFrom(
        this.http.get<BrandCampaign>(
          `${this.path}/${brandId}/campaign/${campaignId}`,
        ),
      );

      const brandCampaign = new BrandCampaign(response.body!);

      this.currentBrandCampaign$.next(brandCampaign);
      this.isLoadingCampaignSource.next(false);

      return brandCampaign;
    } catch (err: any) {
      if (
        err.error?.key === CAMPAIGN_ACCESS_DENIED ||
        err.error?.error?.key === CAMPAIGN_ACCESS_DENIED
      ) {
        const brandId = this.brandService.currentBrandId;

        this.notificationService.error("shared.errorNoAccessToCampaign");

        if (brandId) {
          this.router.navigate([BrandUrl.Home(brandId)]);
        }
      } else if (err instanceof BrandNotSelectedError) {
        throw err;
      } else {
        this.notificationService.error("shared.errorLoadingTheCampaign");
      }
    }
  }

  public getBrandCampaign(
    brandId: number,
    campaignId: number,
  ): Observable<BrandCampaign> {
    return this.http
      .get(`${this.path}/${brandId}/campaign/${campaignId}`)
      .pipe(map(({ body }) => new BrandCampaign(body)));
  }

  public async getBrandCampaignSummary(slug: string): Promise<BrandCampaign> {
    return lastValueFrom(
      this.http
        .get(`public/view-campaign/${slug}`)
        .pipe(map(({ body }) => new BrandCampaign(body))),
    );
  }

  public getBrandCampaigns(
    status: BrandCampaignStatus,
  ): Observable<BrandCampaign[]> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .get(`brand/${brandId}/campaign?status=${status}`)
      .pipe(
        map(({ body }) =>
          body.items.map((campaign: any) => new BrandCampaign(campaign)),
        ),
      );
  }

  public deleteCampaign(campaignId: number): Observable<any> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .del(`brand/${brandId}/campaign/${campaignId}`)
      .pipe(map(({ body }) => body));
  }

  public duplicateCampaign(campaignId: number): Observable<BrandCampaign> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .post(`brand/${brandId}/campaign/${campaignId}/duplicate`, {})
      .pipe(map(({ body }) => new BrandCampaign(body)));
  }

  public moveCampaign(
    campaignId: number,
    moveToBrandId: number,
  ): Observable<void> {
    const brandId = this.brandService.currentBrandId;

    return this.http
      .post(`brand/${brandId}/campaign/${campaignId}/move`, {
        moveToBrandId: moveToBrandId,
      })
      .pipe(map(() => {}));
  }

  public createCampaign(campaign: BrandCampaign): Observable<BrandCampaign> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(`${this.path}/${brandId}/campaign`, campaign)
      .pipe(map(({ body }) => new BrandCampaign(body)));
  }

  public updateBrandCampaign(
    campaign: BrandCampaign,
    brandId?: number,
  ): Observable<BrandCampaign> {
    return this.http
      .put(
        `brand/${brandId ?? this.brandService.currentBrandId}/campaign/${
          campaign.id
        }`,
        campaign,
      )
      .pipe(
        map(({ body }) => new BrandCampaign(body)),
        tap((brandCampaign) => this.currentBrandCampaign$.next(brandCampaign)),
      );
  }

  public updateBrandCampaignDetails(
    campaign: BrandCampaign,
    detail: BrandCampaignDetails,
    brandId?: number,
  ): Observable<BrandCampaignDetails> {
    const baseUrl = `brand/${
      brandId ?? this.brandService.currentBrandId
    }/campaign/${campaign.id}`;

    return this.http.put(`${baseUrl}/detail/${detail.id}`, detail).pipe(
      // TODO: This is a workaround to update the `currentBrandCampaign$` value,
      // we should use `this.currentBrandCampaign` + `cloneWith` instead. See
      // task and changeset for `BE-2255`. TLDR: PUT doesn't return the updated
      // details, that's why we need to fetch the campaign again. Ideally the BE
      // returns the updated details.
      switchMap(() => this.http.get(baseUrl)),
      tap(({ body }) =>
        this.currentBrandCampaign$.next(new BrandCampaign(body)),
      ),
      map(
        ({ body }) =>
          new BrandCampaignDetails(
            body.details.find((d: any) => d.id === detail.id),
          ),
      ),
    );
  }

  public getCampaignDetailAssetUrl(
    campaignId: number,
    detailsId: number,
  ): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/asset`,
    );
  }

  public deleteCampaignDetailAsset(
    campaign: BrandCampaign,
    detailsId: number,
    assetId: number,
  ): Observable<BrandCampaign> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .del(
        `brand/${brandId}/campaign/${campaign.id}/detail/${detailsId}/asset/${assetId}`,
      )
      .pipe(map(({ body }) => new BrandCampaign(body)));
  }

  public getCampaignDetailSliderUrl(
    campaignId: number,
    detailsId: number,
  ): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/slider`,
    );
  }

  public deleteCampaignDetailSlider(
    campaign: BrandCampaign,
    detailsId: number,
    sliderId: number,
  ): Observable<BrandCampaign> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .del(
        `brand/${brandId}/campaign/${campaign.id}/detail/${detailsId}/slider/${sliderId}`,
      )
      .pipe(map(({ body }) => new BrandCampaign(body)));
  }

  public getCreateCampaignDetailCategoryImageUrl(
    campaignId: number,
    detailsId: number,
    categoryId: number,
  ): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/category/${categoryId}/image`,
    );
  }

  public getUpdateCampaignDetailCategoryImageUrl(
    campaignId: number,
    detailsId: number,
    categoryId: number,
    imageId: number,
  ): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/category/${categoryId}/image/${imageId}`,
    );
  }

  public updateCampaignDetailCategoryImage(
    campaignId: number,
    detailsId: number,
    categoryId: number,
    image: BannerEntity,
  ): Observable<BannerEntity> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(
        `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/category/${categoryId}/image/${image.id}`,
        JSON.stringify(image),
      )
      .pipe(map(({ body }) => AnyToBannerEntityMapper(body)));
  }

  public deleteCampaignDetailCategoryImage(
    campaignId: number,
    detailId: number,
    categoryId: number,
    imageId: number,
  ) {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .del(
        `brand/${brandId}/campaign/${campaignId}/detail/${detailId}/category/${categoryId}/image/${imageId}`,
      )
      .pipe(map(({ body }) => body));
  }

  public deleteCampaignDetailCategoryImageFile(
    campaignId: number,
    detailId: number,
    categoryId: number,
    imageId: number,
    fileId: number,
  ) {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .del(
        `brand/${brandId}/campaign/${campaignId}/detail/${detailId}/category/${categoryId}/image/${imageId}/file/${fileId}`,
      )
      .pipe(map(({ body }) => body));
  }

  public getCreateCampaignDetailCategoryVideoUrl(
    campaignId: number,
    detailsId: number,
    categoryId: number,
  ): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/category/${categoryId}/video`,
    );
  }

  public getUpdateCampaignDetailCategoryVideoUrl(
    campaignId: number,
    detailsId: number,
    categoryId: number,
    videoId: number,
  ): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/category/${categoryId}/video/${videoId}`,
    );
  }

  public updateCampaignDetailCategoryVideo(
    campaignId: number,
    detailsId: number,
    categoryId: number,
    video: VideoEntity,
  ): Observable<VideoEntity> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .post(
        `brand/${brandId}/campaign/${campaignId}/detail/${detailsId}/category/${categoryId}/video/${video.id}`,
        JSON.stringify(video),
      )
      .pipe(map(({ body }) => AnyToVideoEntityMapper(body)));
  }

  public deleteCampaignDetailCategoryVideo(
    campaignId: number,
    detailId: number,
    categoryId: number,
    videoId: number,
  ): Observable<void> {
    const brandId = this.brandService.currentBrandId;
    return this.http
      .del(
        `brand/${brandId}/campaign/${campaignId}/detail/${detailId}/category/${categoryId}/video/${videoId}`,
      )
      .pipe(map(({ body }) => body));
  }

  public getCampaignUploadSignUpLogoUrl(campaignId: number): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/sign-up-logo`,
    );
  }

  public getCampaignUploadSignUpBackgroundImageUrl(campaignId: number): string {
    const brandId = this.brandService.currentBrandId;
    return this.http.createRequestUrlV1(
      `brand/${brandId}/campaign/${campaignId}/sign-up-background-image`,
    );
  }
}
