import { TextCaseTransform } from "../shared/functions/text-case-transformations";
import { BrandCampaign } from "../shared/models/brandCampaign";
import { PublicationTemplateType } from "./brand-campaign/publication-template/data/entities/publication-templates.entity";

export const BrandCampaignUrl = (brandId: number, campaignId: number) =>
  `/brand/${brandId}/campaigns/${campaignId}`;

export const BrandUrl = {
  Home: (brandId: number) => `/brand/${brandId}/campaigns`,
  Partners: (brandId: number) => `/brand/${brandId}/partners`,
  LandingPage: (brandId: number) => `/brand/${brandId}/landing-page`,
  Settings: (brandId: number) => `/brand/${brandId}/settings`,
  Campaign: {
    Home: (brandId: number, campaign: BrandCampaign) =>
      campaign.isEditable
        ? BrandUrl.Campaign.DetailsSettings(brandId, campaign.id)
        : BrandUrl.Campaign.Dashboard(brandId, campaign.id),
    Dashboard: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/dashboard`,
    DetailsSettings: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/information/settings`,
    DetailsInfo: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/information/details`,
    DetailsInvitePage: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/information/invite-page`,
    Content: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/content`,
    Publications: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/publications`,
    Ads: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/promoted-ads`,
    AdsSettings: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/promoted-ads?page=settings`,
    AdsChannels: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(
        brandId,
        campaignId,
      )}/promoted-ads?page=marketing-channels`,
    LandingPage: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/landing-page`,
    ContentCalendar: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/content-calendar`,
    InvitePartners: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/invite-partners`,
    Summary: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/summary`,
    Partners: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/partners`,
    Pos: (brandId: number, campaignId: number) =>
      `${BrandCampaignUrl(brandId, campaignId)}/pos`,
    PublicationTemplate: (brandId: number, campaignId: number) => ({
      Edit: (id: string) =>
        `${BrandUrl.Campaign.Publications(
          brandId,
          campaignId,
        )}/templates/${id}/edit`,
      Selection: `${BrandUrl.Campaign.Publications(
        brandId,
        campaignId,
      )}/templates/types`,
      New: (type: PublicationTemplateType) =>
        `${BrandUrl.Campaign.Publications(
          brandId,
          campaignId,
        )}/templates/${TextCaseTransform.snakeToKebab(type)}`,
      Schedule: (id: string) =>
        `${BrandUrl.Campaign.Publications(
          brandId,
          campaignId,
        )}/templates/${id}/schedule`,
    }),
  },
};
