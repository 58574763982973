import { SerializableObject } from "./serializable-object";

import {
  BrandCampaignCalendarPost,
  BrandCampaignFacebookCalendarPost,
  BrandCampaignGoogleCalendarPost,
  BrandCampaignLinkedInCalendarPost,
} from "./brandCampaignCalendarPost";
import { PostPlatform } from "../enums/campaignPost.enums";

export class BrandCampaignCalendar extends SerializableObject {
  id!: number;
  calendarPosts!: BrandCampaignCalendarPost[];
  title!: string;
  order!: number;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "calendarPosts":
        return propertyValue.map((postData: any) => {
          switch (postData.platform) {
            case PostPlatform.Facebook:
              return new BrandCampaignFacebookCalendarPost(postData);
            case PostPlatform.Google:
              return new BrandCampaignGoogleCalendarPost(postData);
            case PostPlatform.LinkedIn:
              return new BrandCampaignLinkedInCalendarPost(postData);
          }
        });
      default:
        return propertyValue;
    }
  }
}
