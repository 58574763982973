import { BannerEntity } from "../entities/file/banner.entity";
import { VideoEntity } from "../entities/file/video.entity";
import { BrandCampaignLinkedInPost } from "./brandCampaignLinkedInPost";
import { SerializableObject } from "./serializable-object";
import { BrandCampaignPost } from "./brandCampaignPost";
import { BrandCampaignFacebookPost } from "./brandCampaignFacebookPost";
import { BrandCampaignGoogleAdPost } from "./brandCampaignGoogleAdPost";
import { PostPlatform } from "../enums/campaignPost.enums";
import { AnyToBannerEntityMapper } from "../mappers/banner.entity.mapper";
import { AnyToVideoEntityMapper } from "../mappers/video.entity.mapper";

export class BrandCampaignCategory extends SerializableObject {
  id!: number;
  active!: boolean;
  images!: BannerEntity[];
  order!: number;
  posts!: BrandCampaignPost[];
  subtitle!: string;
  title!: string;
  videos!: VideoEntity[];

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "images":
        return propertyValue.map(AnyToBannerEntityMapper);
      case "videos":
        return propertyValue.map(AnyToVideoEntityMapper);
      case "posts":
        return propertyValue.map((post: any) => {
          switch (post.platform) {
            case PostPlatform.Facebook:
              return new BrandCampaignFacebookPost(post);
            case PostPlatform.Google:
              return new BrandCampaignGoogleAdPost(post);
            case PostPlatform.LinkedIn:
              return new BrandCampaignLinkedInPost(post);
          }
        });
      default:
        return propertyValue;
    }
  }
}
