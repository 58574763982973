import { SafeUrl } from "@angular/platform-browser";
import { BrandCampaignFacebookPost } from "./brandCampaignFacebookPost";
import { BrandCampaignGoogleAdPost } from "./brandCampaignGoogleAdPost";
import { BrandCampaignLinkedInPost } from "./brandCampaignLinkedInPost";
import { CampaignPost } from "./campaignPost";
import {
  CampaignPostContentType,
  LinkedInPostContentType,
  PostPlatform,
} from "../enums/campaignPost.enums";
import { Image } from "./image";

export type BrandPost =
  | BrandCampaignFacebookPost
  | BrandCampaignGoogleAdPost
  | BrandCampaignLinkedInPost;

export class BrandCampaignPost extends CampaignPost {
  image?: string | SafeUrl;
  imagePublicId!: string;
  imageFileId!: number | null;
  imageFile?: Image;
  mediaType!: CampaignPostContentType | LinkedInPostContentType;
  platform!: PostPlatform;
  suggestedText?: string;
  updatedAt?: Date;
  scheduledPublishDate!: string | Date;
  reviewImageComment!: string;
  isReviewImageCommentResolved!: boolean;
  reviewTextComment!: string;
  isReviewTextCommentResolved!: boolean;
  isReviewApproved!: boolean;
  reviewApprovedBy!: string;
  reviewApprovedAt!: Date;
  videoDuration!: number;

  public get areCommentsApproved(): boolean {
    return (
      this.isReviewTextCommentResolved && this.isReviewImageCommentResolved
    );
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "imageFile":
        return new Image(propertyValue);
      default:
        return propertyValue;
    }
  }
}
