import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import { CampaignAsset } from "./campaignAsset";
import { BrandCampaignCalendar } from "./brandCampaignCalendar";
import { BrandCampaignCategory } from "./brandCampaignCategory";
import { CampaignProduct } from "./campaignProduct";
import { Image } from "./image";
import { Language } from "./language";
import { SerializableObject } from "./serializable-object";

export class BrandCampaignDetails extends SerializableObject {
  id!: number;
  assets!: CampaignAsset[];
  calendars!: BrandCampaignCalendar[];
  categories!: BrandCampaignCategory[];
  description!: string;
  fbOtherInformation!: string;
  language!: Language;
  pos!: CampaignProduct[];
  posAdditionalInformation!: string;
  posIntroText!: string;
  sliders!: Image[];
  subtitle!: string;
  title!: string;

  public get orderedCategories(): BrandCampaignCategory[] {
    if (!this.categories) {
      return [];
    }
    const activeCategories = filter(
      this.categories,
      (cat: BrandCampaignCategory) => cat.active,
    );
    return sortBy(activeCategories, (cat: BrandCampaignCategory) => cat.order);
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "assets":
        return propertyValue.map(
          (asset: any) =>
            new CampaignAsset(
              asset.id,
              asset.file,
              asset.name,
              asset.type,
              asset.size,
            ),
        );
      case "calendars":
        return propertyValue.map(
          (calendar: any) => new BrandCampaignCalendar(calendar),
        );
      case "categories":
        return propertyValue.map(
          (category: any) => new BrandCampaignCategory(category),
        );
      case "language":
        return new Language(propertyValue.locale, propertyValue.name);
      case "pos":
        return propertyValue.map((pos: any) => new CampaignProduct(pos));
      case "sliders":
        return propertyValue.map((slide: any) => new Image(slide));
      default:
        return propertyValue;
    }
  }
}
